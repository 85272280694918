<template>
  <v-container fluid>
    <div v-if="!dialog">
      <!-- Create Button -->
      <v-btn
        class="mr-2"
        fab
        dark
        color="blue"
        :absolute="true"
        :right="true"
        @click="_add"
        v-if="!isViewer"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
      <!-- List Table -->
      <v-card>
        <v-card-title class="mr-10">
          <v-text-field
            v-model="search"
            append-icon="mdi-search"
            label="Search"
            single-line
            hide-details
            @change="gets"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="forms"
          :footer-props="{ 'items-per-page-options': $itemsPerPage }"
          :items-per-page="perpage"
          class="elevation-1"
          :search="search"
          :loading="loading"
          :options.sync="options"
          :server-items-length="countItems"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ generateDate(item.created_at) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              title="edit"
              @click="_edit(item)"
              :disabled="isViewer"
              class="mr-1"
              >mdi-circle-edit-outline</v-icon
            >
            <v-icon
              title="delete"
              @click="_delete(item)"
              :disabled="isViewer"
              class="mr-1"
              >mdi-delete-circle-outline</v-icon
            >
            <v-icon
              title="copy"
              size="22"
              @click="_copy(item)"
              :disabled="isViewer"
              class="mr-1"
              >mdi-content-copy</v-icon
            >
            <v-icon title="survey" @click="_survey(item)" class="mr-1"
              >mdi-link-box-variant-outline</v-icon
            >
            <v-icon title="approve" @click="_approve(item)" class="mr-1"
              >mdi-check-decagram</v-icon
            >
            <v-icon title="chart" @click="_analytics(item)"
              >mdi-chart-areaspline</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-if="dialog">
      <v-toolbar elevation="0">
        <v-btn icon color="blue" large @click="_close">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title v-if="form">
          {{ form.id ? `Edit ${form.name}` : 'Create' }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <h1 class="text-center my-3" v-if="form && form.id">
            Survey ID: {{ form.id }}
          </h1>
          <SurveyCreator
            :key="form && form.id"
            :dialog.sync="dialog"
            :data="form"
            :save.sync="save"
          />
        </v-card-text>
      </v-card>
    </div>
    <!-- Dialog -->
    <!-- <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="_close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="form">{{
            form.id ? `Edit ${form.name}` : 'Create'
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <h1 class="text-center my-3" v-if="form && form.id">
            Survey ID: {{ form.id }}
          </h1>
          <SurveyCreator
            :key="form && form.id"
            :dialog.sync="dialog"
            :data="form"
            :save.sync="save"
          />
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <!-- Confirm -->
    <v-dialog v-model="confirm" max-width="400">
      <v-card>
        <v-card-title v-if="form" class="headline">{{
          form.action === 'copy'
            ? 'Do you want to copy?'
            : 'Do you want to delete?'
        }}</v-card-title>

        <v-card-text v-if="form">{{ form.name }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" text @click="confirm = false"
            >Cancel</v-btn
          >

          <v-btn color="green darken-1" text @click="_confirm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import Form from '@/store/models/Form'

import SurveyCreator from '@/components/SurveyCreator'

export default {
  components: {
    SurveyCreator,
  },
  data: () => {
    return {
      form: {},
      forms: [],
      search: '',
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      perpage: 10,
      countItems: 0,
      loading: false,
      dialog: false,
      save: false,
      confirm: false,
    }
  },
  created() {
    this.gets()
  },
  watch: {
    options: {
      handler() {
        this.gets()
      },
      deep: false,
    },
    projId: {
      handler() {
        this.gets()
      },
      deep: false,
    },
    save: {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          this.form = {}
          this.save = false
          this.gets()
        }
      },
      deep: false,
    },
  },
  computed: {
    projId() {
      return this.$store
        .$db()
        .model('projId')
        .find(1)
    },
    isViewer() {
      const projId = this.projId
      return (
        projId &&
        projId.access &&
        projId.access.role === this.$rolesMember[1].value
      )
    },
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Survey ID',
          value: 'id',
          sortable: false,
        },
        {
          text: 'Callback',
          value: 'callback',
          sortable: false,
        },
        {
          text: 'Created At',
          value: 'created_at',
          width: '200px'
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ]
    },
  },
  methods: {
    generateDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    gets() {
      this.loading = true

      let params = {}

      // Search
      if (this.search) {
        params.query = this.search
      }

      // Per page
      if (this.options && this.options.itemsPerPage) {
        params.perpage = this.options.itemsPerPage
      }

      // Page
      if (this.options && this.options.page) {
        params.page = this.options.page
      }

      if (this.options?.sortBy?.[0]) {
        const name = `data.${[this.options.sortBy[0]]}`
        params.sort = {
          [name]: this.options?.sortDesc?.[0] ? -1 : 1,
        }
      }

      // params.sort = {
      //   created_at: -1,
      // }

      if (params.page && params.perpage && this.projId && this.projId.value) {
        params.condition = {}
        const options = { params }

        Form.gets(this.projId.value, options)
          .then((res) => {
            this.forms = res.data || []
            this.countItems = res.count || 0
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            console.error('Get projects err: ', err)
          })
      }
    },
    _add() {
      this.form = {}
      this.dialog = true
    },
    _edit(item) {
      this.dialog = true
      this.form = item
    },
    _delete(item) {
      if (item.id) {
        this.confirm = true
        this.form = item
      }
    },
    _copy(item) {
      if (item.id) {
        // Set
        this.form = item
        this.form.action = 'copy'
        this.confirm = true
      }
    },
    _confirm() {
      this.loading = true

      let save
      // Clone
      if (this.form && this.form.action === 'copy') {
        save = Form.clone(this.form && this.form.id)
        // Delete
      } else {
        save = Form.api().delete(`/cms/forms/${this.form && this.form.id}`, {
          delete: this.form && this.form.id,
        })
      }

      save
        .then(() => {
          this.confirm = false
          this.form = {}
          this.gets()
        })
        .catch((err) => {
          this.confirm = false
          this.form = {}
          this.loading = false
          console.error('Clone project err: ', err)
        })
    },
    _survey(item) {
      if (item.id) {
        window.open(
          `${process.env.VUE_APP_SURVEY_URL || ''}/${
            item.id
          }?openExternalBrowser=1`,
          '_blank'
        )
      }
    },
    _approve(item) {
      if (item.id) {
        this.$router.push({ path: `/approve/${item.id}` })
      }
    },
    _analytics(item) {
      if (item.id) {
        this.$router.push({ path: `/analytics/${item.id}` })
      }
    },
    _close() {
      this.form = null
      this.dialog = false
    },
  },
}
</script>
